import React from 'react';
import {
  Image,
  ImageBackground,
  Text,
  ScrollView,
  View,
  StyleSheet,
  Linking,
  TouchableOpacity
} from 'react-native';
import cstyles from '../styles/Styles';
import images from '../assets/images';
import {SafeAreaView} from 'react-navigation';
import UserRegistrationComponent from './UserRegistrationComponent';
import Footer from "./Footer";
import {BodyText, HeadingText} from '../util/CopywritingText';

const whatsAppHelloShortURL = 'https://wa.link/6oaynp'

const openURL = async (url) => {
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url)
          .then((response) => {
                console.log(`Linking.openURL(${url}) returned ${response}`);
              }
          )
    } else {
      console.log(`Failed to open this URL: ${url}`);
    }
}

const HomeScreen = ({route}) => {

  return (
    <SafeAreaView style={cstyles.container}>
      <ScrollView style={lstyles.scrollView}>
        <View style={lstyles.rowSplashHeaderGroup}>
          <ImageBackground
            source={images.splashHeaderMedImg}
            style={lstyles.splashHeaderMedImg}>
            <View>
              <Text style={lstyles.companyNameMain}>{HeadingText.COMPANY_NAME}</Text>
            </View>
            <View style={lstyles.mainHeadingEnView}>
              <Text style={lstyles.mainHeading}>{HeadingText.MAIN_HEADING_TEXT_EN}</Text>
            </View>
            <View style={lstyles.mainHeadingHnView}>
              <Text style={lstyles.mainHeading}>{HeadingText.MAIN_HEADING_TEXT_HN}</Text>
            </View>
          </ImageBackground>
        </View>
        <View style={lstyles.rowMissionGroup}>
          <View style={lstyles.colMission}>
            <View><Text style={lstyles.missionHeading}>{HeadingText.INSPIRATION_HEADING_TEXT_EN}</Text></View>
            <View><Text style={lstyles.missionText}>{BodyText.INSPIRATION_TEXT_EN}</Text></View>
            <View><Text style={lstyles.markMeaningEnText}>{BodyText.MARK_MEANING_TEXT_EN}</Text></View>
            <View><Text style={lstyles.missionHeading}>{HeadingText.MISSION_HEADING_TEXT_EN}</Text></View>
            <View><Text style={lstyles.missionText}>{BodyText.MISSION_TEXT_EN}</Text></View>
            <View><Text style={[lstyles.missionHeading, lstyles.missionHeadingHn]}>{HeadingText.MISSION_HEADING_TEXT_HN}</Text></View>
            <View><Text style={lstyles.missionText}>{BodyText.MISSION_TEXT_HN}</Text></View>
          </View>
        </View>
        <View style={lstyles.rowFindWorkersInfoGroup}>
          <View style={lstyles.col1FindWorkersInfoImg}>
            <Image
              source={images.findWorkeriOSSampleWA}
              style={lstyles.findWorkersWAImg}
            />
          </View>

          <View style={lstyles.col2FindWorkersInfoText}>
            <Text style={lstyles.findWorkersHeading}>{HeadingText.FIND_WORKERS_HEADING_TEXT_EN}</Text>
            <Text style={lstyles.findWorkersText}>{BodyText.FIND_WORKERS_INFO_TEXT}</Text>

            <View style={{flexDirection: 'row'}}>
              <View style={lstyles.openInWACol1}>
                <Text style={lstyles.openInWALabel}>{BodyText.FIND_WORKERS_OPEN_IN_WA_LABEL}</Text>
              </View>
              <View style={lstyles.openInWACol2}>
                <TouchableOpacity onPress={() => openURL(whatsAppHelloShortURL).then(r => console.log('open url returned'))}>
                  <Image style={lstyles.waLogo} source={images.waLogo} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <View style={lstyles.rowAddUserInfoGroup}>
          <View style={lstyles.col1AddUserInfoImg}>
              <Image
                source={images.addWorkeriOSSampleWA}
                style={lstyles.addUserWAImg}
              />
          </View>
          <View style={lstyles.col2AddUserInfoText}>
            <Text style={lstyles.addUserHeading}>{HeadingText.ADD_USER_HEADING_TEXT_EN}</Text>
            <Text style={lstyles.addUserText}>{BodyText.ADD_USER_INFO_TEXT}</Text>

            <View style={{flexDirection: 'row'}}>
              <View style={lstyles.openInWACol1}>
                <Text style={lstyles.openInWALabel}>{BodyText.ADD_USER_OPEN_IN_WA_LABEL}</Text>
              </View>
              <View style={lstyles.openInWACol2}>
                <TouchableOpacity onPress={() => openURL(whatsAppHelloShortURL).then(r => console.log('open url returned'))}>
                  <Image style={lstyles.waLogo} source={images.waLogo} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <View style={lstyles.rowUserRegistrationGroup}>
          <UserRegistrationComponent />
        </View>
      </ScrollView>
      <Footer />
    </SafeAreaView>
  );
};

// Styles local to this component
const lstyles = StyleSheet.create({
  scrollView: {
    maxWidth: 980,
    minWidth: 360,
  },

  rowSplashHeaderGroup: {
    width: 'auto',
    height: 'auto',
    marginTop: 10,
  },

  rowMissionGroup: {
    width: 'auto',
    height: 'auto',
    marginTop: 10,
    alignSelf: 'center',
    alignContent: 'center',
  },

  rowFindWorkersInfoGroup: {
    width: 'auto', // 'auto' enables this group's children to wrap around to the next line
    height: 'auto',
    marginTop: 10,
    alignSelf: 'center',
    backgroundColor: 'rgba(233,243,251,1)',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
  },

  rowAddUserInfoGroup: {
    width: 'auto', // 'auto' enables this group's children to wrap around to the next line
    height: 'auto',
    marginTop: 10,
    alignSelf: 'center',
    backgroundColor: 'rgba(233,243,251,1)',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
  },

  rowUserRegistrationGroup: {
    width: 'auto', // 'auto' enables this group's children to wrap around to the next line
    height: 'auto',
    marginTop: 10,
    marginBottom: 50,
    alignSelf: 'center',
  },

  splashHeaderMedImg: {
    width: 'auto',
    height: 400,
    resizeMode: 'cover',
    //borderBottomLeftRadius: 10,
    //borderBottomRightRadius: 10,
    //borderTopRightRadius: 10,
    //borderTopLeftRadius: 10,
    borderRadius: 10,
    overflow: 'hidden',
  },

  mainHeadingEnView: {
    height: 80,
    width: 250,
    marginTop: 50,
    alignSelf: 'flex-end',
    justifyContent: 'center',
    marginRight: 10,
    backgroundColor: 'rgba(220,115,100, 0.8)',
    borderRadius: 5,
  },

  mainHeadingHnView: {
    height: 80,
    width: 250,
    marginTop: 50,
    alignSelf: 'flex-start',
    justifyContent: 'center',
    marginLeft: 10,
    backgroundColor: 'rgba(220,115,100, 0.9)',
    borderRadius: 5,
  },

  mainHeading: {
    color: 'white',
    padding: 10,
    fontSize: 20,
  },

  companyNameMain: {
    marginTop: 15,
    color: '#21619A', // Company logo color
    fontSize: 60,
    fontWeight: 'bold',
    alignSelf: 'center',
    //backgroundColor: 'rgba(220,115,100, 0.8)',
  },

  colMission: {
    maxWidth: 375*2,
    minWidth: 375,
    height: 'auto',
    margin: 5,
  },

  missionHeading: {
    color: '#21619A',
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 8,
    marginBottom: 15,
  },

  missionHeadingHn: {
    fontSize: 30,
  },

  missionText: {
    color: '#5a5a5d',
    textAlign: 'center',
    margin: 5,
    marginBottom: 20,
    fontSize: 18,
  },

  markMeaningEnText: {
    color: '#121212',
    textAlign: 'center',
    margin: 5,
    marginBottom: 20,
  },

  col1FindWorkersInfoImg: {
    width: 375,
    height: 375,
    margin: 5,
    alignItems: 'center',
  },

  col2FindWorkersInfoText: {
    width: 375,
    height: 'auto',
    margin: 5,
    //borderWidth: 1,
  },

  findWorkersWAImg: {
    width: 325,
    height: 325,
    resizeMode: 'contain',
    resizeMethod: 'scale',
    marginTop: 30,
  },

  findWorkersHeading: {
    color: '#21619A',
    fontWeight: 'bold',
    fontSize: 30,
    marginTop: 40,
    padding: 20,
  },

  findWorkersText: {
    color: '#121212',
    fontSize: 18,
    padding: 20,
  },

  findWorkersWAButton: {
    height: 37,
    width: 250,
  },

  col1AddUserInfoImg: {
    width: 375,
    height: 375,
    margin: 5,
    alignItems: 'center',
    //borderWidth: 1,
  },

  addUserWAImg: {
    width: 400,
    height: 325,
    resizeMode: 'contain',
    resizeMethod: 'scale',
    marginTop: 30,
  },

  col2AddUserInfoText: {
    width: 375,
    height: 'auto',
    margin: 5,
    //borderWidth: 1,
  },

  addUserHeading: {
    color: '#21619A',
    fontWeight: 'bold',
    fontSize: 30,
    marginTop: 40,
    padding: 20,
  },

  addUserText: {
    color: '#121212',
    fontSize: 18,
    padding: 20,
  },

  waLogo: {
    width: 50,
    height: 50,
    alignSelf: 'center',
    resizeMode: 'contain',
    resizeMethod: 'scale',
    padding: 20,
  },

  openInWACol1: {
    justifyContent: 'center'
  },

  openInWACol2: {
    shadowColor: 'rgba(146,146,146,0.4)',
    shadowOffset: { height: 1, width: 1 },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 1,
    borderRadius: 4
  },

  openInWALabel: {
    color: '#121212',
    fontSize: 18,
    paddingHorizontal: 20,
    width: 280
  },

});

export default HomeScreen;
