import React from 'react'
import {StyleSheet, View, Text, Linking} from "react-native";
import {TouchableHighlight} from "react-native-web";

const copyrightNotice = "Copyright \u00A9 2021 KaamKonnekt - Connects Workers & Opportunities With Ease. All Rights Reserved.";
const supportEmail = 'support@kaamkonnekt.in';

const Footer = ({route}) => {
    return (
        <View style={lstyles.footerBottom}>
            <View style={lstyles.footerRow}>
                <View style={lstyles.contactUs}>
                    <TouchableHighlight onPress={ () => Linking.openURL('mailto:'.concat(supportEmail))}>
                        <Text style={lstyles.contactUs}>Contact Us</Text>
                    </TouchableHighlight>
                </View>
            </View>
            <View style={lstyles.footerRow}>
                <View style={lstyles.copyrightNotice}>
                    <Text style={lstyles.copyrightNoticeText}>{copyrightNotice}</Text>
                </View>
            </View>
        </View>
    );
};

// Styles local to this component
const lstyles = StyleSheet.create({
    footerBottom: {
        flex: 1,
        justifyContent: 'flex-end',
        backgroundColor: '#21619A', // Logo font color
        alignItems: 'center',
    },

    footerRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-center',
        alignItems: 'center',
        width: 'auto',
        height: 40,
        margin: 7,
    },

    contactUs: {
        color: 'white',
        fontSize: 14,
    },

    copyrightNotice: {
        width: 'auto',
    },
    copyrightNoticeText: {
        color: 'white',
        fontSize: 10,
    },
});

export default Footer;
