import 'react-native-gesture-handler';

/**
 * @format
 */

import React from 'react';
import ReactDom from 'react-dom';
import MainApp from './App';
import './index.css';
import iconFontMaterialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';

const iconFontStyleMaterialIcons = `@font-face {
  src: url(${iconFontMaterialIcons});
  font-family: MaterialIcons;
}`;

// Create stylesheet for icon fonts
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyleMaterialIcons;
} else {
    style.appendChild(document.createTextNode(iconFontStyleMaterialIcons));
}

// Inject stylesheet
document.head.appendChild(style);

ReactDom.render(<MainApp />, document.getElementById('root'));
