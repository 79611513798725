const HeadingText = Object.freeze({
    COMPANY_NAME: 'KaamKonnekt',
    INSPIRATION_HEADING_TEXT_EN: 'OUR INSPIRATION',
    MISSION_HEADING_TEXT_EN: 'OUR MISSION',
    MISSION_HEADING_TEXT_HN: 'हमारा लक्ष्',
    MAIN_HEADING_TEXT_EN: 'Connects you with work opportunities with ease.',
    MAIN_HEADING_TEXT_HN: 'आपको आसानी से काम के अवसरों से जोड़ता है।',
    FIND_WORKERS_HEADING_TEXT_EN: 'Find Workers',
    ADD_USER_HEADING_TEXT_EN: 'Join KaamKonnekt',
});

const BodyText = Object.freeze({
    INSPIRATION_TEXT_EN:
        'India’s millions of laborers, artisans, tailors, and other semi-skilled and unskilled workers are an essential part of country’s growing economy. And yet, they primarily rely on inner circles, friends and family, to find their next job. Similarly, employers seeking this type of workers lack job boards to find a suitable match. These limitations result in lost opportunities, gaps in workers’ cash flow, and thus seriously affecting their livelihood.\n\n' +
        'KaamKonnekt is going to change that. It is a marketplace for workers and potential employers to directly connect online and start a conversation to explore job opportunities.',

    MISSION_TEXT_EN:
        'KaamKonnekt helps semi-skilled and unskilled workers connect with work opportunities with ease for continuity in their employment and better livelihood.',

    MISSION_TEXT_HN:
        'KaamKonnekt अर्ध-कुशल और अकुशल श्रमिकों को काम के अवसरों से आसानी से जुड़ने में मदद करता है ताकि उन्हें रोज़गार में निरंतरता और बेहतर आजीविका प्राप्त हो।',

    MARK_MEANING_TEXT_EN: 'Kaam = Work, Konnekt = Connect',

    FIND_WORKERS_INFO_TEXT:
        'Are you looking for semi-skilled and unskilled workers?' +
        '\n\n' +
        'Use KaamKonnekt in WhatsApp to send a simple query message to get a list of' +
        ' workers matching a skill and a locality of your choice.',

    FIND_WORKERS_OPEN_IN_WA_LABEL:
        'Open in WhatsApp, say Hello and start finding workers : ',

    ADD_USER_OPEN_IN_WA_LABEL:
        'Open in WhatsApp, say Hello and add yourself as a worker : ',

    ADD_USER_INFO_TEXT:
        'Add yourself as a semi-skilled, unskilled worker or as someone' +
        ' who hires workers, by sending a simple message to KaamKonnekt using WhatsApp.' +
        '\n\n' +
        'Just enter your name, your skill and a locality you prefer to work. KaamKonnekt will register you' +
        ' in its database that is searched by employers.',

    WA_SAY_HELLO_AND_FIND_TEXT:
        `Open ${HeadingText.COMPANY_NAME} in WhatsApp and Say Hello`,
});

export {BodyText, HeadingText};
