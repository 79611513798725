import {StyleSheet} from 'react-native';

// Styles common across the app
const cstyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    maxWidth: 980,
    minWidth: 360,
    alignSelf: 'center'
  },

  buttonContainer: {
    backgroundColor: '#007AFF',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 5,
    paddingLeft: 16,
    paddingRight: 16,
  },

  buttonCaption: {
    color: '#fff',
    fontSize: 17,
  },

  logo: {
    width: 175,
    height: 50,
    alignSelf: 'left',
    resizeMode: 'contain',
    resizeMethod: 'scale',
    margin: 10,
  },

  splashHeading2: {
    color: 'rgb(255, 255, 255)',
    fontSize: 15,
    textAlign: 'center',
    alignSelf: 'center',
  },

  sectionHeading1: {
    color: 'rgb(9,71,146)',
    fontSize: 30,
    textAlign: 'center',
    alignSelf: 'center',
  },

  sectionBody1: {
    color: 'rgb(45,44,44)',
    fontSize: 15,
    textAlign: 'left',
    alignSelf: 'center',
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },



  button: {
    fontSize: 20,
    paddingLeft: 12,
    paddingRight: 12,
    color: 'rgb(112, 76, 182)',
    paddingBottom: 4,
    backgroundColor: 'rgba(112, 76, 182, 0.1)',
    borderRadius: 2,
    width: 150,
    textAlign: 'center',
    alignSelf: 'center',
  },

  textbox: {
    fontSize: 20,
    padding: 4,
    width: 350,
    textAlign: 'left',
    marginBottom: 4,
    borderWidth: 1,
    alignSelf: 'center',
  },
});

export default cstyles;
