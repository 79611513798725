const images = {
  waLogo: require('./images/WhatsApp_logo_50x50.png'),
  splashHeaderMedImg: require('./images/construction_site_grey_splash_image_770x400.png'),
  addWorkeriOSSampleWA: require('./images/add_worker_wa_iphone.png'),
  findWorkeriOSSampleWA: require('./images/find_workers_wa_iphone.png'),
  logo: require('./images/KaamKonnekt-logo.png'),
  menuIconWhite: require('./images/menuIconWhite.png'),
  menuIconBlack: require('./images/menuIconBlack.png'),
  menuIcon: require('./images/kkt_stacked_menu_35x35.png'),
};

export default images;
