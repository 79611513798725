import React, {useState, useContext} from 'react';
import {
  ActivityIndicator,
  FlatList,
  SafeAreaView, ScrollView, StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import 'url-search-params-polyfill';
import Icon from 'react-native-vector-icons/dist/MaterialIcons';

import UserContext from './User';
import Footer from "./Footer";
import cstyles from '../styles/Styles';

const apiURLPrefix = (process.env['API_URL'] === undefined) ? 'http://localhost:3000' : process.env['API_URL'];
const apiURL = apiURLPrefix.concat('/users/find_by_skills_and_localities');

const FindWorkersScreen = ({route}) => {
  const [userQuery, setUserQuery] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [workersCount, setWorkersCount] = useState(-1);
  const [responseSuccess, setResponseSuccess] = useState("");
  const [responseError, setResponseError] = useState("");

  const userContext = useContext(UserContext);
  const user = userContext.user;
  const userName = user.item.name;
  const registeredUserPhoneNum = user.item.phoneNumber;

  const findWorkersBySkills = async () => {
    setLoading(true);

    const body = new URLSearchParams();
    body.append('query', userQuery.toString());
    body.append('From', registeredUserPhoneNum.toString());

    try {
      const requestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body,
      };

      let response = await fetch(apiURL, requestInit);

      if (!response.ok) {
        throw new Error('Fetch failed ' + response);
      }

      let responseJSON = await response.json();
      setResponseSuccess(responseJSON.success);
      setResponseError(responseJSON.error);
      let response_data = responseJSON.user_records;
      setData(response_data);
      setWorkersCount(response_data.length);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const Item = ({item, onPress, style}) => (
    <TouchableOpacity onPress={onPress}>
      <View style={lstyles.workersRectRow}>
        <View style={[lstyles.workerCol]}>
          <Text style={lstyles.workerName}>{item.user_name}</Text>
          <Text style={lstyles.workerSkill}>{item.skill}</Text>
        </View>
        <Text style={[lstyles.workerCol, lstyles.workerLocality]}>{item.nearby_locality}</Text>
        <Text style={[lstyles.workerCol, lstyles.workerMobileNumber]}>{item.phone_number}</Text>
      </View>
    </TouchableOpacity>
  );

  const renderItem = ({item}) => {
    const backgroundColor = ((item.user_id + item.nearby_rank) === selectedId)? '#73c2e5' : '#e4eaea';

    return (
      <Item
        item={item}
        onPress={() => setSelectedId(item.user_id + item.nearby_rank)}
        style={{backgroundColor}}
      />
    );
  };

  return (
    <SafeAreaView style={cstyles.container}>
      <ScrollView style={lstyles.scrollView}>
        <Text style={lstyles.findWorkersHeading}>
          Find Workers
        </Text>
        <Text style={lstyles.greetingText}>Hello {userName}</Text>
        <Text style={lstyles.findHelpText}>
          Please enter a search query to find workers with a skill and in a locality of
          your choice. {'\n\n'}For example, you may use this query to find
          plumbers near Parade:
          <Text style={lstyles.searchSampleText}>Plumbers near parade</Text>
        </Text>
        <View style={lstyles.searchBarContainer}>
          <View style={lstyles.inputBox}>
            <Icon name="search" style={lstyles.inputLeftIcon}/>
            <TextInput
              placeholder="Plumbers near parade"
              enablesReturnKeyAutomatically={true}
              maxlength={100}
              style={lstyles.inputStyle}
              onChangeText={(text) => setUserQuery(text)}
            />
          </View>

          <TouchableOpacity
              style={[cstyles.buttonContainer, lstyles.findButton]}
              onPress={() => findWorkersBySkills()}>
            <Text style={cstyles.buttonCaption}>Search</Text>
          </TouchableOpacity>
        </View>

        <View style={lstyles.workersScrollRect}>
          {isLoading ? (
            <ActivityIndicator />
          ) : (
              {data} && data.length ? (
                  <FlatList
                    data = {data}
                    keyExtractor = {({user_id, nearby_rank}) => {
                      return user_id.toString() + nearby_rank.toString();
                    }}
                    renderItem={renderItem}
                  />
                  ) : (
                    workersCount !== -1 ? (
                      <Text style={lstyles.noResultsFound}>No results found. Please try again with a different search criteria.</Text>
                    ) : (<Text/>)
                )
          )}
        </View>
      </ScrollView>
      <Footer />
    </SafeAreaView>
  );
};

// Styles local to this component
const lstyles = StyleSheet.create({
  scrollView: {
    maxWidth: 980,
    minWidth: 360,
  },

  findWorkersHeading: {
    color: '#21619A',
    fontSize: 60,
    margin: 5,
    fontWeight: 'bold',
  },

  workersScrollRect: {
    width: 380,
    height: 368,
    marginTop: 20,
    alignSelf: 'center',
  },

  workersRectRow: {
    padding: 3,
    backgroundColor: '#edf2f8',
    fontSize: 16,
    marginVertical: 3,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  workerCol: {
    height: 40,
    fontSize: 16,
  },
  workerName: {
    width: 106,
    marginRight: 2,
    fontSize: 16,
  },
  workerSkill: {
    width: 106,
    marginRight: 2,
    fontSize: 14,
  },
  workerLocality: {
    width: 120,
    marginRight: 2,
  },
  workerMobileNumber: {
    width: 150,
    marginRight: 2,
  },
  greetingText: {
    color: '#121212',
    marginTop: 10,
    alignSelf: 'left',
    padding: 4,
    fontSize: 20,
  },
  findHelpText: {
    color: '#121212',
    marginTop: 10,
    alignSelf: 'left',
    padding: 4,
    fontSize: 20,
  },
  searchSampleText: {
    color: '#121212',
    marginTop: 10,
    alignSelf: 'left',
    padding: 4,
    fontStyle: 'italic',
    fontSize: 20,
  },
  searchBarContainer: {
    alignItems: 'center',
    backgroundColor: 'rgba(233,243,251,1)',
    padding: 8,
    height: 100,
    borderRadius: 5,
    width: 375,
    marginTop: 10,
    alignSelf: 'center',
  },
  inputBox: {
    flex: 1,
    flexDirection: 'row',
    width: 360,
    backgroundColor: 'white',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputLeftIcon: {
    color: 'lightgrey',
    fontSize: 20,
    alignSelf: 'center',
    padding: 5
  },
  inputStyle: {
    height: 32,
    fontSize: 20,
    lineHeight: 25,
    color: '#000',
    flex: 1,
    paddingLeft: 5,
  },

  findButton: {
    height: 37,
    width: 150,
    marginTop: 8,
    alignSelf: 'center',
  },
  noResultsFound: {
    color: '#121212',
    marginTop: 10,
    alignSelf: 'left',
    padding: 4,
    fontSize: 20,
  },

});

export default FindWorkersScreen;
