import React, {useContext, useEffect, useState} from 'react';
import {StyleSheet, Text, TextInput, TouchableOpacity, View, ScrollView} from 'react-native';
import {Controller, useForm} from 'react-hook-form';
import {useNavigation} from '@react-navigation/native';
import Icon from 'react-native-vector-icons/dist/MaterialIcons';
import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import 'url-search-params-polyfill';
import cstyles from '../styles/Styles';
import UserContext from './User';

const apiURLPrefix = process.env['API_URL'];
const apiURL = apiURLPrefix.concat('/users/create');
const apiURLGetLocalities = apiURLPrefix.concat('/locality_categories/get_categories_localities');

const UserRegistrationComponent = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [fullName, setFullName] = useState(firstName + ' ' + lastName);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [skills, setSkills] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [localitiesRefData, setLocalitiesRefData] = useState([]);
  const [city, setCity] = useState('Kanpur');
  const [country, setCountry] = useState('India');
  const [isLoading, setLoading] = useState(false);
  const [regUserFullName, setRegUserFullName] = useState('');
  const [apiResponseStatus, setApiResponseStatus] = useState('Not Started');
  const [apiLocalitiesResponseStatus, setApiLocalitiesResponseStatus ] = useState('Not Started');
  const [maxItems, setMaxItems] = useState(2);
  const { setUser } = useContext(UserContext);
  const navigation = useNavigation();

  let responseError = '';
  let responseErrorLocalities = '';
  let responseUserName = '';
  let promoCode = '';

  function currentDateTime() {
      let date = new Date().getDate();
      let month = new Date().getMonth() + 1;
      let year = new Date().getFullYear();
      let hours = new Date().getHours();
      let mins = new Date().getMinutes();
      let secs = new Date().getSeconds();

      return month + '/' + date + '/' + year + ' ' + hours + ':' + mins + ':' + secs;
  };

  const UserMessage = Object.freeze({
    INITIAL_MESSAGE:
      'You will be taken to Find Workers screen upon a successful registration.',
    SUCCESS: 'Successfully registered.',
    UNKNOWN_ERROR: 'Something went wrong.',
    VALIDATION_FAILED:
      'Error: The phone number is already registered. Please use another number.',
  });

  const ValidPattern = Object.freeze({
    INTL_PHONE_NUM: /^\+[0-9]{1,3}?[-. ]?[0-9]{4,5}?[-. ]?[0-9]{4,5}?$/,
  });

  const {control, handleSubmit, errors, reset, clearErrors} = useForm();

  const getLocalities = async () => {

      const queryParam = new URLSearchParams();
      queryParam.append('city', city.toString());

      const requestOptions = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: queryParam,
      };

      await fetch(apiURLGetLocalities, requestOptions)
          .then((response) => {
              if (response.ok) {
                  setApiLocalitiesResponseStatus('Ok');
              } else {
                  setApiLocalitiesResponseStatus('Error');
                  throw new Error(apiURLGetLocalities,
                      ' fetch GET failed. status=',
                      response.status,
                      ', and response=',
                      response,
                  );
              }

              return response.json();
          })
          .then((result) => {
              responseErrorLocalities = result.error;
              if (responseErrorLocalities === undefined) { // Success
                  setLocalitiesRefData(result);
              } else {
                  setApiLocalitiesResponseStatus('Error');
              }

          })
          .catch((errors) => {
              console.error(getLocalities.name, ': Error: ', errors);
          })
          .finally((info) => {
          });
  };

  const registerUser = async () => {
    setLoading(true);
    setFullName(firstName + ' ' + lastName);

    const body = new URLSearchParams();
    body.append('name', fullName.toString());
    body.append('phone_number', phoneNumber.toString());
    body.append('skills', skills.toString());
    body.append('localities', localities.toString());
    body.append('From', phoneNumber.toString());

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body,
    };

    await fetch(apiURL, requestOptions)
      .then((response) => {
        if (response.ok) {
          setApiResponseStatus('Ok');
        } else {
          setApiResponseStatus('Error');
          throw new Error(
            'UserRegistration Fetch POST failed. status=',
            response.status,
            ', and response=',
            response,
          );
        }

        return response.json();
      })
      .then((result) => {
        responseError = result.error;
        responseUserName = result.user_name;
        promoCode = result.promo_code;
        if (responseError === undefined) { // Successful user registration
          // The API returns user's full name as a result
          // This setState() update and context update of the user data
            // is required for retaining the state value
            // while navigating the user to FindWorkers screen (thru useEffect()).
          setRegUserFullName(responseUserName);
          setUser({
              "name": responseUserName,
              "phoneNumber": phoneNumber
          });
        } else if (responseError.startsWith('ValidationError:')) {
          setApiResponseStatus('ValidationError');
        } else {
          setApiResponseStatus('Error');
        }
      })
      .catch((error) => {
        console.error(registerUser.name, ': Error: ', error);
      })
      .finally((info) => {
        setLoading(false);
      });
  };

  useEffect(() => {
/*    console.log(currentDateTime(), ' - ',
      useEffect.name,
      ': API response status=', apiResponseStatus,
        ' , and Registered User Name= ', regUserFullName);*/

    if (apiLocalitiesResponseStatus === 'Not Started') {
        getLocalities();
    }

    if (apiResponseStatus === 'Not Started' && regUserFullName === '') {
        clearErrors();
    }
    // Go to the next screen if the user registration was successful
    if (apiResponseStatus === 'Ok' && regUserFullName !== '') {
        reset(); // safe to clear the fields on the input form
        setApiResponseStatus('Not Started'); // reset to the initial state
        navigation.navigate('Find Workers');
    };
  }, [regUserFullName, navigation, apiResponseStatus, localitiesRefData]);

  return (
    <View style={lstyles.rowUserRegistration}>
        <View style={lstyles.col1UserRegInfoText}>
            <Text style={lstyles.userRegHeading}>
                Register Yourself using KaamKonnekt Web
            </Text>
            <Text style={lstyles.registerRectHeading1}>
                You can join KaamKonnekt now using this simple form to instantly start
                searching for workers.
            </Text>
        </View>
        <View style={lstyles.col2UserRegFields}>
          <Text style={lstyles.fieldsRequiredText}>All fields are required.</Text>
          <View style={lstyles.userRegRow}>
            <View style={lstyles.firstName}>
              <Text style={lstyles.firstNameLabel}>First Name</Text>
              <Controller
                name="firstName"
                control={control}
                rules={{required: true}}
                defaultValue=""
                render={({onChange, onBlur, value}) => (
                  <TextInput
                    placeholder="First Name"
                    autoCapitalize="words"
                    keyboardType="name-phone-pad"
                    returnKeyType="done"
                    maxLength={50}
                    style={lstyles.firstNamePlaceholder}
                    /* eslint-disable-next-line no-shadow */
                    onChangeText={(value) => {
                      onChange(value);
                      setFirstName(value);
                    }}
                    value={value}
                    onBlur={onBlur}
                  />
                )}
              />
              {errors.firstName && (
                <Text style={lstyles.fieldsInvalidErrMsg}>Required</Text>
              )}
            </View>
            <View style={lstyles.lastName}>
              <Text style={lstyles.lastNameLabel}>Last Name</Text>
              <Controller
                name="lastName"
                control={control}
                rules={{required: true}}
                defaultValue=""
                render={({onChange, onBlur, value}) => (
                  <TextInput
                    placeholder="Last Name"
                    autoCapitalize="words"
                    keyboardType="name-phone-pad"
                    returnKeyType="done"
                    maxLength={50}
                    style={lstyles.lastNamePlaceholder}
                    /* eslint-disable-next-line no-shadow */
                    onChangeText={(value) => {
                      onChange(value);
                      setLastName(value);
                      setFullName(firstName.concat(' ', value));
                    }}
                    value={value}
                    onBlur={onBlur}
                  />
                )}
              />
              {errors.lastName && (
                <Text style={lstyles.fieldsInvalidErrMsg}>Required</Text>
              )}
            </View>
          </View>
          <View style={lstyles.userRegRow}>
            <View style={lstyles.mobileNum}>
              <Text style={lstyles.mobileNumber1}>Mobile Number</Text>
              <Controller
                name="mobileNum"
                control={control}
                rules={{
                  required: {value: true, message: 'Required'},
                  pattern: {
                    value: ValidPattern.INTL_PHONE_NUM,
                    message: 'Invalid number.',
                  },
                  minLength: {value: 9, message: 'Too short'}, // 7 min length of a local number plus 1 min country code plus 1 for +
                }}
                defaultValue=""
                render={({onChange, onBlur, value}) => (
                  <TextInput
                    placeholder="+91 12345 67890"
                    autoCapitalize="none"
                    keyboardType="phone-pad"
                    returnKeyType="done"
                    maxLength={50}
                    dataDetector="phoneNumber"
                    style={lstyles.mobileNumPlaceholder}
                    /* eslint-disable-next-line no-shadow */
                    onChangeText={(value) => {
                      onChange(value);
                      setPhoneNumber(value);
                      setApiResponseStatus('Not Started'); // reset post any validation failed occurrences.
                    }}
                    value={value}
                    onBlur={onBlur}
                    error={errors.mobileNum}
                    errorText={errors.mobileNum?.message}
                  />
                )}
              />
              {errors.mobileNum && (
                <Text style={lstyles.fieldsInvalidErrMsg}>
                  {errors.mobileNum?.message}
                </Text>
              )}
              {apiResponseStatus === 'ValidationError' && (
                <Text style={lstyles.fieldsInvalidErrMsg}>Already registered.</Text>
              )}
            </View>
            <View style={lstyles.skills}>
              <Text style={lstyles.skillsLabel}>
                Skills (N/A if you hire workers)
              </Text>
              <Controller
                name="skills"
                control={control}
                rules={{required: true}}
                defaultValue=""
                render={({onChange, onBlur, value}) => (
                  <TextInput
                    placeholder="E.g., Plumber"
                    autoCapitalize="words"
                    keyboardType="name-phone-pad"
                    returnKeyType="done"
                    maxLength={50}
                    style={lstyles.skillsPlaceholder}
                    /* eslint-disable-next-line no-shadow */
                    onChangeText={(value) => {
                      onChange(value);
                      setSkills(value);
                    }}
                    value={value}
                    onBlur={onBlur}
                  />
                )}
              />
              {errors.skills && (
                <Text style={lstyles.fieldsInvalidErrMsg}>Required</Text>
              )}
            </View>
          </View>
          <View style={lstyles.userRegRow}>
            <View style={lstyles.workCity}>
              <Text style={lstyles.cityLabel}>City</Text>
              <Text style={lstyles.cityPlaceholder}>{city}, {country}</Text>
            </View>
            <View style={lstyles.localities}>
              <Text style={lstyles.localitiesLabel}>Main Work Locality</Text>
              <Controller
                name="localities"
                control={control}
                rules={{required: true}}
                defaultValue=""
                render={({onChange}) => (
                    <SectionedMultiSelect
                        items={localitiesRefData}
                        uniqueKey="id"
                        onSelectedItemsChange={(value) => {
                            onChange(value);
                            setLocalities(value);
                        }}
                        IconRenderer={Icon}
                        single
                        showCancelButton
                        readOnlyHeadings
                        expandDropDowns
                        showChips={false}
                        subKey="localities"
                        selectText="Select..."
                        searchPlaceholderText="Search localities..."
                        selectedItems={localities}
                        styles={{
                            container: {
                                maxWidth: 300,
                                maxHeight: 700
                            },
                            selectToggle: {
                                width: 175,
                                padding: 0,
                                margin: 0
                            },
                            selectToggleText: {
                                fontSize: 14,
                                color: '#121212'
                            }
                        }}
                    />
                )}
              />
              {errors.localities && (
                <Text style={lstyles.fieldsInvalidErrMsg}>Required</Text>
              )}
            </View>
          </View>
          <View style={lstyles.userRegRow}>
            <TouchableOpacity
              style={[cstyles.buttonContainer, lstyles.registerSubmitButton]}
              onPress={() => {
                handleSubmit(registerUser)();
                clearErrors();
              }}>
              <Text style={cstyles.buttonCaption}>Submit</Text>
            </TouchableOpacity>
            <View style={lstyles.regProgressStatus}>
              <Text style={lstyles.regProgressStatus}>
                {apiResponseStatus === 'Not Started'
                  ? UserMessage.INITIAL_MESSAGE
                  : ''}
                {apiResponseStatus === 'Ok' && regUserFullName !== ''
                  ? UserMessage.SUCCESS
                  : ''}
                {apiResponseStatus === 'ValidationError'
                  ? UserMessage.VALIDATION_FAILED
                  : ''}
                {apiResponseStatus === 'Error' ? UserMessage.UNKNOWN_ERROR : ''}
              </Text>
            </View>
          </View>
        </View>
    </View>
  );
};

// Styles local to this component
const lstyles = StyleSheet.create({

    rowUserRegistration: {
        width: 'auto', // 'auto' enables this group's children to wrap around to the next line
        height: 'auto',
        marginTop: 10,
        alignSelf: 'center',
        backgroundColor: 'rgba(233,243,251,1)',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'center',
    },


    col1UserRegInfoText: {
        width: 375,
        height: 'auto',
        margin: 5,
    },

    col2UserRegFields: {
        width: 375,
        height: 240,
        marginTop: 30,
        margin: 5,
        alignItems: 'left',
    },

    userRegHeading: {
        color: '#21619A',
        fontSize: 25,
        marginLeft: 10,
    },

    registerRectHeading1: {
        color: '#121212',
        fontSize: 14,
        marginLeft: 10,
        marginTop: 5,
    },

    fieldsRequiredText: {
        color: '#121212',
        marginTop: 6,
        marginLeft: 11,
        fontSize: 12,
        fontStyle: 'italic',
    },
    fieldsInvalidErrMsg: {
        color: 'red',
        fontSize: 12,
        fontStyle: 'italic',
    },
    firstName: {
        width: 120,
        height: 41,
        justifyContent: 'space-between',
    },
    firstNameLabel: {
        color: '#121212',
        fontSize: 12,
    },
    firstNamePlaceholder: {
        color: '#121212',
        height: 24,
        width: 120,
        borderWidth: 1,
        borderColor: 'rgba(168,171,180,1)',
        borderRadius: 5,
    },
    lastName: {
        width: 120,
        height: 41,
        justifyContent: 'space-between',
        marginLeft: 10,
    },
    lastNameLabel: {
        color: '#121212',
        fontSize: 12,
    },
    lastNamePlaceholder: {
        color: '#121212',
        height: 24,
        width: 120,
        borderWidth: 1,
        borderColor: 'rgba(168,171,180,1)',
        borderRadius: 5,
    },
    mobileNum: {
        width: 120,
        height: 38,
        justifyContent: 'space-between',
    },
    mobileNumber1: {
        color: '#121212',
        fontSize: 12,
    },
    mobileNumPlaceholder: {
        color: '#121212',
        height: 24,
        width: 120,
        borderWidth: 1,
        borderColor: 'rgba(168,171,180,1)',
        borderRadius: 5,
    },
    skillsPlaceholder: {
        color: '#121212',
        height: 24,
        width: 120,
        borderWidth: 1,
        borderColor: 'rgba(168,171,180,1)',
        borderRadius: 5,
    },
    skills: {
        width: 120,
        height: 39,
        justifyContent: 'space-between',
        marginLeft: 10,
    },
    skillsLabel: {
        color: '#121212',
        fontSize: 12,
        width: 171,
    },

    localities: {
        width: 200,
        height: 50,
        marginLeft: 10,
    },
    localitiesLabel: {
        color: '#121212',
        fontSize: 12,
        width: 171,
    },
    cityPlaceholder: {
        color: '#121212',
        height: 24,
        width: 120,
        borderWidth: 0,
        borderColor: 'rgba(168,171,180,1)',
        borderRadius: 0,
        marginTop: 2,
    },
    workCity: {
        width: 120,
        height: 39,
        justifyContent: 'space-between',
    },
    cityLabel: {
        color: '#121212',
        fontSize: 12,
        width: 120,
    },
    registerSubmitButton: {
        height: 37,
        width: 120,
    },
    userRegRow: {
        height: 41,
        flexDirection: 'row',
        marginTop: 10,
        marginLeft: 11,
        marginRight: 5,
    },
    regProgressStatus: {
        width: 220,
        height: 37,
        color: '#121212',
        marginTop: 1,
        marginLeft: 10,
        fontSize: 12,
        fontStyle: 'italic',
    },
});

export default UserRegistrationComponent;
