import 'react-native-gesture-handler';
/**
 * @format
 */

import React, {useReducer} from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import {
  NavigationContainer,
} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import {createDrawerNavigator} from '@react-navigation/drawer';

import images from './assets/images';
import cstyles from './styles/Styles';
import HomeScreen from './features/Home';
import FindWorkersScreen from './features/FindWorkers';
import UserRegistrationScreen from './features/UserRegistration';
import UserContext from './features/User';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const initialUser = {
    "item": {
        "name": "",
        "phoneNumber": "",
    },
};

function reducer(state, item) {
    return {...state, item};
}

function LogoTitle(props) {
    const gotoHomeScreen = () => {
        props.navigation.navigate('KaamKonnekt');
    };

    return (
        <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={() => gotoHomeScreen()}>
                <Image style={cstyles.logo} source={images.logo} />
            </TouchableOpacity>
        </View>
    );
}

const DrawerButton = (props) => {
    const toggleDrawer = () => {
        props.navigation.toggleDrawer();
    };

    return (
      <View>
          <TouchableOpacity onPress={() => toggleDrawer()}>
              <Image
                  source={images.menuIcon}
                  style={{
                      width: 30,
                      height: 30,
                      margin: 5
                  }}
              />
          </TouchableOpacity>
      </View>
    );
};

function HomeScreenStack({navigation}) {
    return (
        <Stack.Navigator initialRouteName="KaamKonnekt">
            <Stack.Screen
                name="KaamKonnekt"
                component={HomeScreen}
                options={({route, navigation}) => ({
                    headerTitle: '', // Do not show the header title
                    headerLeft: () => (
                        <DrawerButton navigation={navigation}/>
                        ),
                    headerRight: () => (
                        <LogoTitle navigation={navigation} />
                        ),
                    headerStyle: {
                        backgroundColor: 'white',
                    },
                })}
            />
        </Stack.Navigator>
    );
}

function UserRegistrationScreenStack({navigation, route}) {
    return (
        <Stack.Navigator initialRouteName="User Registration">
            <Stack.Screen
                name="User Registration"
                component={UserRegistrationScreen}
                options={({route, navigation}) => ({
                    headerTitle: "", // Do not show the header title
                    headerLeft: () => (
                        <DrawerButton navigation={navigation}/>
                    ),
                    headerRight: () => (
                        <LogoTitle navigation={navigation} />
                    ),
                    headerStyle: {
                        backgroundColor: 'white',
                    },
                })}
            />
        </Stack.Navigator>
    );
}

function FindWorkersScreenStack({navigation, route}) {
    return (
        <Stack.Navigator initialRouteName="Find Workers">
            <Stack.Screen
                name="Find Workers"
                component={FindWorkersScreen}
                initialParams={route}
                options={({route, navigation}) => ({
                    headerTitle: "", // Do not show the header title
                    headerLeft: () => (
                        <DrawerButton navigation={navigation}/>
                    ),
                    headerRight: () => (
                        <LogoTitle navigation={navigation} />
                    ),
                    headerStyle: {
                        backgroundColor: 'white',
                    },
                })}
            />
        </Stack.Navigator>
    );
}

const MainApp = () => {
    const [user, setUser] = useReducer(reducer, initialUser);

  return (
      <UserContext.Provider value={{user, setUser}}>
          <NavigationContainer>
            <Drawer.Navigator
                initialRouteName='KaamKonnekt'
                drawerStyle={{width: 240, height: 400}}
                drawerContentOptions={{
                    activeTintColor: 'rgb(129,184,227)',
                    itemStyle: {marginVertical: 5},
                }}
            >
                <Drawer.Screen
                  name="KaamKonnekt"
                  component={HomeScreenStack}
                  options={{drawerLabel: 'Home'}}
                />
                <Drawer.Screen
                  name="User Registration"
                  component={UserRegistrationScreenStack}
                  options={{drawerLabel: 'User Registration'}}
                />
                <Drawer.Screen
                  name="Find Workers"
                  component={FindWorkersScreenStack}
                  options={{drawerLabel: 'Find Workers'}}
                />
          </Drawer.Navigator>
        </NavigationContainer>
      </UserContext.Provider>
  );
};

export default MainApp;
